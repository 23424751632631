import React, { FC } from 'react';
import styled from 'styled-components';
import { DynamicImage, Text } from 'components';
import StartNowButton from './StartNowButton';
import { mobile, useQuery } from 'styles/breakpoints';

interface TimelineProps {
  title: string;
  label: string;
  image: string;
  imageMobile: string;
  onClick?: () => void;
  bgColor?: string;
  maxWidth?: string;
  maxTitleWidth?: string;
  hideCta?: boolean;
  paddingDesktop?: string;
  paddingMobile?: string;
  marginDesktop?: string;
  marginMobile?: string;
  titleMarginDesktop?: string;
  titleMarginMobile?: string;
  imageMarginDesktop?: string;
  imageMarginMobile?: string;
}

const Container = styled.div<{
  bgColor: string;
  paddingDesktop: string;
  paddingMobile: string;
  marginDesktop: string;
  marginMobile: string;
}>`
  background-color: ${({ bgColor }) => bgColor};
  width: 100%;
  display: flex;
  justify-content: center;
  padding: ${({ paddingDesktop }) => paddingDesktop};
  margin: ${({ marginDesktop }) => marginDesktop};
  @media ${mobile} {
    padding: ${({ paddingMobile }) => paddingMobile};
    margin: ${({ marginMobile }) => marginMobile};
  }
`;

const InnerWrapper = styled.div`
  max-width: 71.25rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled(Text)<{
  titleMargin: string;
  titleMarginMobile: string;
  maxTitleWidth: string;
}>`
  font-size: 1.625rem;
  font-weight: 700;
  text-align: center;
  max-width: ${({ maxTitleWidth }) => maxTitleWidth};
  margin: ${({ titleMargin }) => titleMargin};
  @media ${mobile} {
    font-size: 1.5rem;
    margin: ${({ titleMarginMobile }) => titleMarginMobile};
  }
`;

const Button = styled(StartNowButton)``;

const Image = styled(DynamicImage)<{
  maxWidth: string;
  imageMarginDesktop: string;
  imageMarginMobile: string;
}>`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
  margin: ${({ imageMarginDesktop }) => imageMarginDesktop};
  @media ${mobile} {
    margin: ${({ imageMarginMobile }) => imageMarginMobile};
    width: 100%;
  }
`;

const Label = styled(Text)`
  color: #4d4d4d;
  font-size: 0.75rem;
  text-align: center;
  margin-top: 0.5rem;
`;

const Timeline: FC<TimelineProps> = ({
  title,
  label,
  image,
  imageMobile,
  onClick,
  bgColor = '#FBFBFF',
  maxWidth = '31.25rem',
  maxTitleWidth = '31.25rem',
  hideCta,
  paddingDesktop = '0',
  paddingMobile = '0',
  titleMarginDesktop = '0',
  titleMarginMobile = '0',
  imageMarginDesktop = '0',
  imageMarginMobile = '0',
  marginDesktop = '0',
  marginMobile = '0',
}) => {
  const { isMobile } = useQuery();

  return (
    <Container
      bgColor={bgColor}
      paddingDesktop={paddingDesktop}
      paddingMobile={paddingMobile}
      marginDesktop={marginDesktop}
      marginMobile={marginMobile}
    >
      <InnerWrapper>
        {title ? (
          <Title
            titleMargin={titleMarginDesktop}
            titleMarginMobile={titleMarginMobile}
            maxTitleWidth={maxTitleWidth}
          >
            {title}
          </Title>
        ) : null}
        {label ? <Label>{label}</Label> : null}
        <Image
          src={isMobile ? imageMobile : image}
          maxWidth={maxWidth}
          imageMarginDesktop={imageMarginDesktop}
          imageMarginMobile={imageMarginMobile}
        />
        {hideCta ? null : <Button onClick={onClick}>Get my plan</Button>}
      </InnerWrapper>
    </Container>
  );
};

export default Timeline;
